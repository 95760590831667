
.dashboard-elements {
    width: 100%;
    padding: 1.5em;
    margin-top: 3em;
}

.element {
    position: relative;
    width: 100%;
    height: 270px;
    border-radius: 15px;
    background-color: #384452;
    margin-bottom: 3em;
}

.element > img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height:  100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    background-color: black;
    border-radius: 15px;
}


.link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .8em 0;
}

.link-container > a {
    position: absolute;
    top: 40%;
    color: white;
    text-decoration: none;
    text-transform: capitalize;
    font-size: .8rem;
    background-color: black;
    padding: 15px 30px;
    padding-bottom: 17px;
    border-radius: 30px;
}


@media (min-width: 400px){

    .element {
        width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1071px){

    .element {
        width: 27vw;
    }
    .dashboard-elements {
        display: flex;
        height: 75vh;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }

    .link-container > a:hover {
        background-color: white;
        color: black;
        font-weight: 600;
    }
}