.left-side,
.right-side {
    display: none;
}


@media (min-width: 1069px){

    .full-gossip {
        display: flex;
        justify-content: space-evenly;
    }

    .left-side{
        flex: .3;
    }

    .right-side {
        flex: .3
    }

    .gossip-section {
        flex: .4;
    }


}

.gossip-section {
    margin-top: 4em;
}

.gossip-img-container {
    width: 100%;
    height: auto;
    padding: 1.5em;
}

.gossip-img-container > img {
    width: 100%;
}

.gossip-content,
.backarrow-container{
    padding: 1.5em;
}

.gossip-heading {
    margin-bottom: 2em;
}

.gossip-header {
    margin-bottom: .5em;
}

.gossip-para {
    font-size: .92rem;
    line-height: 40px;
}

.gossip-date {
    font-size: .9rem;
}

.add-comments-section {
    background-color: whitesmoke;
    margin-top: 2em;
}

.add-comments-section__header {
    padding: 2em 0 1em 1em;
}

.add-comments-section > form {
    padding: 0;
    margin: 0;
    padding: 2em  1em;
}


.commentor-input-container{
    padding: .5em;
    background-color: white;
    border-radius: 30px;
}


.commentor-input-container > input,
.comment-input-container > textarea{
    border: none;
    outline-width: 0;
    margin-bottom: 1em;
    padding-left: 10px;
    width: 100%;
}

.comment-input-container > textarea {
    height: 200px;
    margin-top: 2em;
    width: 100%;
    border-radius: 10px;
    padding-top: 20px;
}

.comments-form > button {
    width: 100%;
    background-color: black;
    color: white;
    border: none;
    padding: 12px 0;
    border-radius: 30px;
    margin-top: 2em;
    margin-bottom: 1em;
}


@media (min-width: 715px){

    .add-comments-section > form{
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1069px){

    .comments-form > button:hover {
        background-color: white;
        color: black;
        border: 2px solid #707070;
        font-weight: 500;
    }


}

/* Comments */
.comments {
    padding: 1.5em;
    margin-top: 2em;
}

.comments-heading {
    font-size: 30px;
    text-transform: capitalize;
    margin-top: 1em;
    margin-bottom: 2em;
}

.comment {
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 1.5em;
}

.commentor-info {
    display: flex;
    align-items: center;
}

.circle {
    width: 40px;
    height: 40px;
    background-color: #2699FB;
    border-radius: 50%;
}

.commentor-name {
    margin-left: 1em;
    text-transform: capitalize;
    font-weight: 500;
}

.comment-para {
    margin-top: 2em;
    font-size: .9rem;
    line-height: 30px;
}



