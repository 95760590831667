.gossip-update-form-container {
    background-color: whitesmoke;
}


.gossip-update-name {
    background-color: white;
    padding: .5em;
    border-radius: 30px;
    margin-bottom: 2em;
}

.gossip-update-body {
    background-color: white;
    border-radius: 10px;
    height: 500px;
}

.gossip-update-body > textarea,
.gossip-update-name > input {
    width: 100%;
    height: 100%;
    padding: 10px;
    outline-width: 0;
    border: none;
}



@media (min-width: 1071px){

    .gossip-update-form {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

.image-input-container {
    margin-top: 2em;
}

.submit-gossip-update-container {
    margin: 3em 0;
}

.submit-gossip-update-btn {
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 30px;
    padding: 12px 0;
}

@media (min-width: 1071px){

    .submit-gossip-update-btn{
        width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .submit-gossip-update-btn:hover {
        background-color: white;
        color: black;
        font-weight: 600;
    }
    
}