.add-accomodation {
    margin-top: 3em;
}

.add-accomodation__btn-container {
    display: flex;
    padding: 0 2em;
    justify-content: flex-end;
}

.add-accomodation-btn {
    padding: 12px 30px;
    background-color: black;
    color: white;
    border-radius: 30px;
    cursor: pointer;
    border: none;
}

/* Add Accomodation Form Styles */

.add-accomodation-form {
    background-color: #384452;
    transition: 5ms ease-in;
}

@media (min-width: 1071px){

    .add-accomodation-form {
        margin-left: auto;
        margin-right: auto;
    }
}

.add-accomodation__header {
    margin-bottom: 2em;
    color: white;
    text-align: center;
    font-size: 1.5rem;
}

.address-input-container,
.conditions-input-container,
.available-input-container {
    background-color: white;
    padding: .5em;
    border-radius: 30px;
    margin-bottom: 2em;
}

.address-input-container > input,
.conditions-input-container > input,
.available-input-container > select {
    width: 100%;
    border: none;
    padding-left: 12px;
    outline-width: 0;
}

.submit-accomodation-container {
    margin: 2em 0 1em;
}

.submit-accomodation {
    width: 100%;
    padding: 12px 0;
    background-color: black;
    color: white;
    border-radius: 30px;
    border: none;
}

/*  available accomodation */

.available-accomodations {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}