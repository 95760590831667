.intro-header-container {
    margin-top: 1em;
    margin-bottom: 1em;
}

.intro-header-container > h1 {
    text-transform: capitalize;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    line-height: 40px;
}

h1 > span {
    display: block;
    font-size: 24px;
    margin-top: .3em;
}

.intro-para-container {
    padding: 1em 1.5rem;
}

.intro-para {
    text-align: center;
    line-height: 30px;
    font-size: 12px;
}

@media (min-width: 942px){
    
    .intro-header-container {
        margin-top: 0;
    }

    .intro-header-container > h1 {
        font-size: 2.5rem;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    h1 > span {
        font-size: 27px;
        letter-spacing: 0;
    }
    .intro-para-container {
        width: 520px;
        margin-left: auto;
        margin-right: auto;
        line-height: 35px;
    }


    .intro-para {
        font-size: 14px;
    }

    main {
        padding-top: 5em;
        height: 88vh;
        background: linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)), url("../../public/secondary.jpg");
        background-position: center;
        background-size: cover;
        color: white;
    }
}


/* Products link */

.store-link-container {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}
.store-link {
    padding: 15px 30px;
    background-color: #384452;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    font-size: .8rem;
    display: flex;
    font-weight: 600;
    align-items: center;
}

.store-link span > img {
    width:  12px;
    margin: 5px 0 0 10px;
}

@media (min-width: 942px){

    .store-link:hover {
         background: transparent;
         border: 2px solid #384452;
    }
}



/* Contact Form Styling */

.contact-form-container {
    margin-top: 5em;
}

.contact-form-container__header {
    text-align: center;
    text-transform: capitalize;
    font-size: 2.2rem;
}

section.contact-section {
    background-color: whitesmoke;
    margin-top: 4em;
}

form {
    margin-top: 2em;
    padding: 1.5rem;
    padding-top: 3em;
}

.contact-img-container {
    display: none;
}

@media (min-width: 713px){

    
    form {
        width: 713px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1071px){

    form {
        width: 500px;
        margin-left: 0;
        margin-right: 0;
    }

    section.contact-section {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .contact-form-container__header {
        font-size: 2.6rem;
        margin-bottom: 2em;
        margin-top: 2em;
    }

    .contact-img-container {
        display: block;
        margin-top: 3em;
        transform: rotateZ(30deg);
        box-shadow: 8px 8px 8px 8px black;
        transition: .4s ease;
    }

    .contact-img-container > img {
        width: 470px;
        padding: 1.5em;
        
        
    }
}


.name-input-container,
.email-input-container,
.message-input-container{
    background-color: white;
    padding: .5em;
    border-radius: 30px;
    margin-bottom: 2em;
}

.message-input-container {
    border-radius: 10px;
}

.name-input-container > input,
.email-input-container > input,
.message-input-container > textarea {
    border: none;
    width: 100%;
    outline-width: 0;
    padding: 10px;
}

.message-input-container > textarea {
    width: 100%;
    height: 200px;
}

.submit-btn {
    background-color: black;
    padding: 15px 0;
    text-align: center;
    width: 100%;
    border: none;
    color: white;
    border-radius: 30px;
}

.submit-btn:hover {
    background-color: white;
    color: black;
    border: 2px solid black;
    font-weight: 500;
}

footer {
    background-color: black;
    color: white;
    padding: 1em 0;
    text-align: center;
}

footer > p {
    font-size: .9rem;
}