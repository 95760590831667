* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

html {
  scroll-behavior: smooth;
}
  
  
  body {
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    overflow-x: hidden;
  }
  
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto', sans-serif;
  }
  
  
  