/* Service Section style */

.services {
    margin-top: 5em;
}

.services-container {
    margin-top: 4em;
}
@media (min-width: 1280px){
    
    .services-container {
        display: flex;
    }
}



.services-header {
    text-transform: capitalize;
    text-align: center;
    font-size: 2rem;
}

@media (min-width: 657px){
    
    .services-header {
        font-size: 2.6rem;
    }
}

.service {
    padding: 2em 1.5em;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 4em;
}



@media (min-width: 400px){
    
    .service {
        width: 379px;
        margin-left: auto;
        margin-right: auto;
    }

}

.img-container {
    height: 250px;
}

.img-container > img {
    width: 100%;
}

.service-header {
    text-align: center;
    text-transform: uppercase;
    margin-top: 1em;
    margin-bottom: 1em;
}

.products-service {
    margin-bottom: 3.8em;
}

.services-service {
    margin-bottom: 5em;
}

@media (min-width: 657px){
    
    .service-header {
        margin-top: 2em;
    }
}

.service-desc {
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    font-weight:  600;
}

.link-to-service-container {
    margin-top: 2em;
    display: flex;
    justify-content: center;
}

.link-to-service {
    padding: 12px 30px;
    text-decoration: none;
    background-color: black;
    color: white;
    border-radius: 30px;
    text-transform: capitalize;
}

.link-to-service:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
    
}